<template>
	<v-card>
		<v-container id="printMe">
			<v-row justify="center" style="display: grid;">
				<v-col cols="12">
					<qr-code :text="textQR" error-level="H" :size="360"> </qr-code>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<v-btn @click="print()">print</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
	import qrCode from "vue-qr-generator";
	import VueHtmlToPaper from "vue-html-to-paper";
	import { jsPDF } from "jspdf";


	export default {
		props: {
			textQR: {
				type: String,
				default: ''
			},
		},

		components: {
			qrCode,
		},

		data() {
			return {
				output: null
			}
		},

		mounted () {
			/* var img = document.querySelector("img");
			const doc = new jsPDF();
			doc.addImage(img, "JPEG", 5, 5);
			doc.save('rr.pdf'); */
			setTimeout(() => {
				//window.print()
			}, 100);
		},

		methods: {
			async print () {
				// Pass the element id here
				await this.$htmlToPaper('printMe');
			}
		},

		
	}
</script>

<style scoped>

</style>